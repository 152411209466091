import "@fortawesome/fontawesome-free/css/all.css";
import Vue from "vue";
import Vuetify, { VSnackbar, VBtn, VIcon } from "vuetify/lib";
import ptBr from "./ptBr";
import VueMask from "v-mask";
Vue.use(VueMask);

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
  },
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#89c4c7",
        secondary: "#D2D9D1",
        accent: "#3D5AFE",
        background: "#f8f8fc",
      },
      dark: {
        primary: "#89c4c7",
        secondary: "#171817",
        accent: "#3D5AFE",
        background: "#363636",
      },
    },
  },
  lang: {
    locales: { ptBr },
    current: "ptBr",
  },
});
